/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import { useIntl } from 'react-intl'
import { KTSVG } from '../../../helpers'
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub'
import { AsideMenuItem } from './AsideMenuItem'

export function AsideMenuMain() {
  const intl = useIntl()

  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title='Escritorio'
        fontIcon='bi-app-indicator'
      />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Menu</span>
        </div>
      </div>
      <AsideMenuItemWithSub
        to='/crafted/pages'
        title='Académico'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      >
        <AsideMenuItemWithSub to='/academico/OfertaEducativa/' title='Oferta Educativa' hasBullet={true}>
          <AsideMenuItem to='/academico/OfertaEducativa/Oferta' title='Oferta' hasBullet={true} />
          <AsideMenuItem to='/academico/OfertaEducativa/CrearOferta' title='Crear Oferta' hasBullet={true} />
          {/*<AsideMenuItem to='/crafted/pages/profile/campaigns' title='Campaigns' hasBullet={true} />*/}
          {/*<AsideMenuItem to='/crafted/pages/profile/documents' title='Documents' hasBullet={true} />*/}
          {/*<AsideMenuItem*/}
          {/*  to='/crafted/pages/profile/connections'*/}
          {/*  title='Connections'*/}
          {/*  hasBullet={true}*/}
          {/*/>*/}
        </AsideMenuItemWithSub>
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/reportes'
        title='Reportes'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      >
       <AsideMenuItem to='/reportes' title='Ver Reporte' hasBullet={false} />
      </AsideMenuItemWithSub>
     
       <AsideMenuItem to='/logout' title='Logout' hasBullet={false} />
      
      
      {/* <AsideMenuItemWithSub
        to='/crafted/pages'
        title='Matriculas'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      >
        <AsideMenuItem
            to='academico/wizards/OfertList'
            title='Lista'
            hasBullet={true}
          />
      </AsideMenuItemWithSub> */}
    </>
  )
}
